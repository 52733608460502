import * as userActionTypes  from '../_actionTypes/userActionTypes';

const loggedIn = false


export const user = (state = { loggedIn }, action) => {
    switch(action.type){
      case userActionTypes.AUTH:
        return {
        user: action.user,
        loggedIn: true,
        token: action.token
        }
      case userActionTypes.LOGIN_REQUEST:
        return{
          loggingIn: true,
          user: action.phone
        };
      case userActionTypes.LOGIN_SUCCESS:
        return{
          ...state,
          user: action.user,
          loggedIn: true,
          token: action.token
        };
      case userActionTypes.LOGIN_FAILURE:
        return {};  
      case userActionTypes.LOGOUT:
        return {};
      default:
        return state
    }
}
