import * as alertActionTypes  from '../_actionTypes/alertActionTypes';

export const success = message => {
    return {
        type: alertActionTypes.SUCCESS,
        message
    };
}

export const error = message => {
    return {
        type: alertActionTypes.ERROR,
        message
    };
}

export const clear = () => {
    return {
        type: alertActionTypes.CLEAR
    }
}