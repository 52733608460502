import { combineReducers } from 'redux';

import { alert } from './alertReducer';
import { user } from './userReducer';
import { market } from './marketReducer'
import { util } from './utilReducer'
import { orders } from './orderReducer'
import { staff } from './staffReducer'
 
export const rootReducer = combineReducers({
    alert,
    user,
    market,
    util,
    orders,
    staff
});