import * as marketActionTypes  from '../_actionTypes/marketActionTypes';

export const market = (state = { }, action) => {
    switch(action.type){
      case marketActionTypes.GET:
        return state = action.market
      case marketActionTypes.UPDATE:
        return state = action.market
      default:
        return state
    }
}
