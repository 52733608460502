import * as alertActionTypes  from '../_actionTypes/alertActionTypes';

export const alert = (state = {}, action) => {
    switch(action.type){
        case alertActionTypes.SUCCESS:
          return{
              type: 'success',
              message: action.message
          };
        case alertActionTypes.ERROR:
          return{
              type: 'danger',
              message: action.message
          };
        case alertActionTypes.CLEAR:
          return {};
        default:
          return state    
    }
}